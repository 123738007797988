const parsePackageContent = (content) => {
    const parser = new DOMParser();
    const xml = parser.parseFromString(content, "application/xml");
    let spine = {};
    let styles = [];
    let max = 0;
    let toc = { mediaType: "", href: "" };
    Array.from(xml.querySelectorAll("spine itemref")).forEach((ir, idx) => {
        const idref = ir.getAttribute("idref");
        if (idref)
            spine[idref] = { order: idx + 1 };
        max = idx + 1;
    });
    Array.from(xml.querySelectorAll("manifest item")).forEach((ir) => {
        const id = ir.id;
        const mediaType = ir.getAttribute("media-type") || "";
        const href = ir.getAttribute("href") || "";
        if (spine[id]) {
            spine[id] = {
                ...spine[id],
                href: href,
                mediaType: mediaType,
            };
            spine[spine[id].order] = spine[id];
        }
        if (mediaType === "text/css" && href) {
            styles.push({ id, href });
        }
        if (ir.getAttribute("properties") === "nav" || id === "toc") {
            toc = { href, mediaType };
        }
    });
    return { spine, styles, maxSections: max, toc };
};
export default parsePackageContent;
