export function getOrCreateId(elem) {
    let { id } = elem;
    if (!id) {
        id = `z${new Date().getTime()}`;
        elem.setAttribute("id", id);
    }
    return id;
}
export function getElement(elementOrIdOrClass, defaultValue) {
    if (!elementOrIdOrClass)
        return defaultValue;
    if (elementOrIdOrClass instanceof Element)
        return elementOrIdOrClass;
    if (typeof elementOrIdOrClass === "string")
        return document.querySelector(elementOrIdOrClass);
    return defaultValue;
}
export function getFontSize(element) {
    var style = window
        .getComputedStyle(element, null)
        .getPropertyValue("font-size");
    return parseFloat(style);
}
