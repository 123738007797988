import BaseObject from "./BaseObject.js";
import { getOrCreateId, getElement } from "./utils.js";
/**
 * LocationChangeObserver
 * Uses IntersectionObserver to determine the current "location" based on the
 * currently visible element at the top of the scroll container
 */
export default class LocationObserver extends BaseObject {
    _scroller;
    _selector; // selector for the elements to observe
    _observer;
    _container;
    _isPaused = false;
    _intersectionRatios = new Map();
    static Events = {
        locationChanged: "locationChanged",
    };
    constructor(container, selectorForElementsToObserve, scrollerElement = ".scroller" // HTMLElement or Element ID/Class
    ) {
        super();
        this._scroller = getElement(scrollerElement, container?.parentElement);
        this._selector = selectorForElementsToObserve;
        this._container = container;
    }
    /**
     * Sets up IntersectionObserver for asynchronously monitoring elements
     * that intersect with the #scroller/container
     */
    observeForLocationChange(containerElement) {
        let observer = this._observer;
        const container = containerElement ?? this._container;
        const prefix = "LocationObserver";
        if (!observer) {
            info(`${prefix} - initializing`);
            const root = this._scroller;
            const parent = this._scroller; //container.parentElement;
            let width = parent ? parent.clientWidth : window.innerWidth;
            width = (width - container.clientWidth) / 2;
            const options = {
                root: root,
                rootMargin: width > 0 ? `0px ${width}px` : "0px",
                threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
            };
            info(`${prefix} - observer options: `, options);
            observer = new IntersectionObserver(this.handleIntersect.bind(this), options);
            this._observer = observer;
        }
        if (!observer) {
            warn(prefix, " - IntersectionObserver is null!");
            return;
        }
        info(prefix, `-> setting up IntersectionObserver for ${container.id}`);
        const elements = container.querySelectorAll(this._selector || "*[id]");
        Array.from(elements).forEach((e) => observer.observe(e));
        info(prefix, `-> done setting up IntersectionObserver for ${elements.length} elements!`);
    }
    disconnect() {
        this._observer?.disconnect();
        this._observer = undefined;
    }
    get kind() {
        return this.constructor.name;
    }
    get isPaused() {
        return this._isPaused;
    }
    pause() {
        this._isPaused = true;
    }
    resume() {
        this._isPaused = false;
    }
    handleIntersect(entries) {
        if (this.isPaused)
            return;
        let topElem;
        const { _intersectionRatios: intersectionRatios } = this;
        for (const entry of entries) {
            if (!entry.isIntersecting)
                continue;
            const targetElem = entry.target;
            const id = getOrCreateId(targetElem);
            const { intersectionRatio, boundingClientRect: { bottom }, } = entry;
            if (!intersectionRatios.has(id))
                intersectionRatios.set(id, [intersectionRatio, bottom]);
            const [prevIntersectionRatio, prevBottom] = intersectionRatios.get(id);
            const [topElemIntersectionRatio] = topElem
                ? intersectionRatios.get(topElem.id)
                : [Number.MAX_VALUE, undefined];
            if (intersectionRatio < prevIntersectionRatio &&
                bottom < prevBottom &&
                (!topElem || topElemIntersectionRatio < intersectionRatio)) {
                // scrolling up and target is the top element
                topElem = targetElem;
            }
            else if (intersectionRatio > prevIntersectionRatio &&
                bottom > prevBottom &&
                (!topElem || topElemIntersectionRatio < intersectionRatio)) {
                // scrolling down and target is the top element
                topElem = targetElem;
            }
            intersectionRatios.set(id, [intersectionRatio, bottom]);
        }
        if (!topElem)
            return;
        this.fireLocationChanged(topElem);
    }
    setIntersectionRatio(id, intersectionRatio, bottom) {
        this._intersectionRatios.set(id, [intersectionRatio, bottom]);
    }
    onLocationChanged(callback) {
        this.on(LocationObserver.Events.locationChanged, callback);
        return this;
    }
    fireLocationChanged(topElement) {
        this.emit(LocationObserver.Events.locationChanged, { topElement });
    }
}
