import { default as LocationObserverBase } from "../LocationObserver.js";
import { getOrCreateId } from "../utils.js";
/**
 * Uses IntersectionObserver to determine the current "location" based on the
 * currently visible element at the start of the scroll container
 */
export default class LocationObserver extends LocationObserverBase {
    constructor(container, selectorForElementsToObserve, scrollerElement = ".scroller" // HTMLElement or Element ID/Class
    ) {
        super(container, selectorForElementsToObserve, scrollerElement);
    }
    handleIntersect(entries) {
        if (this.isPaused)
            return;
        const allEntries = Array.from(entries.filter((e) => e.isIntersecting === true));
        //console.log(`${allEntries.length} intersecting entries.`);
        if (!allEntries.length)
            return;
        const firstEntry = allEntries[0];
        const lastEntry = allEntries[allEntries.length - 1];
        const { _intersectionRatios: intersectionRatios } = this;
        let topElem;
        if (isLeftScrolling(lastEntry)) {
            info("LocationObserver[spread] - scrolling to the left!");
            const { target } = lastEntry;
            topElem = target.nextElementSibling ?? target;
        }
        else if (isRightScrolling(firstEntry)) {
            info("LocationObserver[spread] - scrolling to the right!");
            topElem = firstEntry.target;
        }
        else {
            info("LocationObserver[spread] - there are no top or bottom intersecting entries!");
        }
        allEntries.forEach((e) => {
            const { target, intersectionRatio, boundingClientRect: { right }, } = e;
            const id = getOrCreateId(target);
            this.setIntersectionRatio(id, intersectionRatio, right);
        });
        if (topElem) {
            this.fireLocationChanged(topElem);
            info("LocationObserver[spread] - locationChanged! id=", topElem.id);
        }
        else {
            /// TODO: this.warn("unable to determine top element!");
        }
        function isLeftScrolling(entry) {
            const { isIntersecting, target, intersectionRatio, boundingClientRect: { right }, } = entry;
            const id = getOrCreateId(target);
            const [prevIntersectionRatio, prevRight] = intersectionRatios.has(id)
                ? intersectionRatios.get(id)
                : [0, 0];
            return (isIntersecting &&
                intersectionRatio < prevIntersectionRatio &&
                right < prevRight &&
                intersectionRatio <= 0.2);
        }
        function isRightScrolling(entry) {
            const { isIntersecting, target, intersectionRatio, boundingClientRect: { right }, } = entry;
            const id = getOrCreateId(target);
            const [prevIntersectionRatio, prevRight] = intersectionRatios.has(id)
                ? intersectionRatios.get(id)
                : [0, 0];
            return (isIntersecting &&
                intersectionRatio > prevIntersectionRatio &&
                right > prevRight &&
                intersectionRatio >= 0.85);
        }
    }
}
