import BaseObject from "./BaseObject.js";
import ContentLoader from "./ContentLoader.js";
import LocationObserver from "./LocationObserver.js";
import { default as SpreadLocationObserver } from "./spread/LocationObserver.js";
import PageLoadObserver from "./PageLoadObserver.js";
import { default as SpreadPageLoadObserver } from "./spread/PageLoadObserver.js";
import { LoadingTypes } from "./Interfaces/ScrollHandler.js";
import selectionFormatter from "./DefaultSelectionFormatter.js";
import DefaultPopupProvider from "./DefaultPopupProvider.js";
import { getFontSize } from "./utils.js";
const SPREAD_CLASS = "spread";
/**
 * Loads and renders a 'Book' - section at a time - when scrolling!
 * inject: highlighterFactory, selectionFormatter, popupProvider, popupHandler
 */
class Book extends BaseObject {
    _options;
    _renderTo;
    _contentLoader;
    _package = {};
    _location; // current location (id of the top most visible element)
    _locationChangeObserver;
    _scrollHandler;
    _isLoading;
    _spinner;
    _selectionPopup;
    _highlighter;
    _popupProvider;
    _popupHandler;
    _selectionFormatter = selectionFormatter;
    _popupDebounceTimer;
    static Events = {
        locationChanged: "locationChanged",
        sectionRendered: "sectionRendered",
        tocLoaded: "tocLoaded",
    };
    static DefaultOptions = {
        opfPackageUrl: "",
        mode: "continuous",
        location: { section: 1 },
        verbose: false,
        enableHighlights: true,
    };
    static DefaultRenderToElementId = "viewer";
    constructor(options) {
        super();
        const opts = typeof options === "string"
            ? { opfPackageUrl: options }
            : options;
        this._options = {
            ...Book.DefaultOptions,
            ...opts,
        };
        info("Book - constructor");
    }
    /**
     * renders content to the specified element
     * @param {ren} element - target HTMLElement or elementId
     * @param {*} options - rendering options
     */
    async render(element, bookOptions) {
        info("Book.render");
        if (bookOptions) {
            const { _options } = this;
            this._options = { ..._options, ...bookOptions };
        }
        const { _options: options, _options: { opfPackageUrl: opfUrl }, } = this;
        if (!opfUrl)
            throw Error("OPF Package URL must be specified!");
        info("Book.render - rendering...options=", options);
        const contentUrl = opfUrl.substring(0, opfUrl.lastIndexOf("/"));
        if (!element)
            element = Book.DefaultRenderToElementId;
        const renderTo = element instanceof HTMLElement
            ? element
            : document.getElementById(element) ?? document.body;
        if (!renderTo)
            throw Error(`Book.render - Invalid render target element '${element}'. Expected DOM Element or Element ID.`);
        this._renderTo = renderTo;
        this._contentLoader = new ContentLoader(opfUrl, contentUrl);
        info(`Book.render - loading spine from ${opfUrl}`);
        const packageInfo = (this._package = await this._contentLoader.getSpine());
        if (!packageInfo) {
            throw Error("Book.render - Unable to load book /Package Info from the specified OPF Package!");
        }
        info(`Book.render - successfully loaded spine!`, packageInfo);
        const { spine, styles, toc } = packageInfo;
        if (!spine) {
            throw Error("Book.render - spine not found in the specified OPF Package!");
        }
        this.createStyles(styles, contentUrl);
        this.loadToc(toc);
        // set default styles
        let { location: loc, mode } = options;
        if (mode === "spread") {
            const { scroller } = this;
            if (scroller && !scroller.classList.contains(SPREAD_CLASS))
                scroller.classList.add(SPREAD_CLASS);
            info("Book.render - spread style set!");
        }
        // load default chapter/section
        const sectionId = loc?.section > 0 ? loc?.section : 1;
        this.loadAndRenderSection(sectionId, false, true);
        // register event handlers
        if (options.enableHighlights && this.popupProvider) {
            document.addEventListener("selectionchange", this.handleSelection.bind(this));
        }
        info("Book.render - done!");
    }
    /**
     * navigates to new location: book spine href or sectionId
     * @param spineHrefOrSectionId - href of spine/chapter OR sectionId (number)
     * @returns
     */
    navigateTo(spineHrefOrSectionId) {
        const whereTo = spineHrefOrSectionId;
        info(`Book.navigateTo: ${whereTo}`);
        if (!whereTo || !this._package?.spine) {
            warn("invalid location or spine not found on package!");
            return;
        }
        const { _package: { spine: spines }, } = this;
        let spine;
        const sectionId = typeof whereTo === "number" ? whereTo : Number(whereTo);
        if (!sectionId) {
            const spineKey = Object.keys(spines).find((key) => spines[key].href === whereTo);
            if (!spineKey) {
                warn(`Book.navigateTo - unable to find spine for href: '${whereTo}'`);
                return;
            }
            spine = spines[spineKey];
        }
        else
            spine = spines[sectionId];
        if (!spine) {
            warn(`Book.navigateTo - unable to find spine for location ${whereTo}.`);
            return;
        }
        const { order: newSectionId } = spine;
        const { _options: options, _options: { location: { section: currentSectionId }, }, } = this;
        if (newSectionId === currentSectionId) {
            info(`section ${newSectionId} is already active!`);
            this.emit(Book.Events.sectionRendered, { sectionId: currentSectionId });
            return true;
        }
        this._options = { ...options, location: { section: newSectionId } };
        this.resetContent();
        this.loadAndRenderSection(newSectionId, false, true);
        return true;
    }
    get currentLocation() {
        return this._location;
    }
    get spine() {
        return this._package?.spine;
    }
    get verbose() {
        return this._options.verbose;
    }
    get viewMode() {
        return this._options.mode;
    }
    updateOptions(options) {
        this._options = { ...this._options, ...options };
    }
    set viewMode(value) {
        if (this._options.mode === value)
            return;
        this.updateOptions({ mode: value });
        this._locationChangeObserver?.disconnect();
        this._locationChangeObserver = undefined;
        this.observeForLocationChange(this._renderTo);
        // const topSection = this._scrollHandler.topSection;
        this._scrollHandler?.disconnect();
        this._scrollHandler = undefined;
    }
    resetContent() {
        if (this._renderTo)
            this._renderTo.innerHTML = "";
    }
    createStyles(styles, contentUrl) {
        info("Book - creating styles");
        if (!styles?.length) {
            info("Book - styles not found!");
            return;
        }
        const head = document.head ?? document.querySelector("head");
        for (const style of styles) {
            const elem = document.createElement("link");
            elem.href = `${contentUrl}/${style.href}`;
            elem.rel = "stylesheet";
            head.appendChild(elem);
            info(`Book - created style: ${elem.href}`);
        }
    }
    async loadToc(toc) {
        if (!toc || !toc.href)
            return;
        const content = await this._contentLoader?.getToc(toc.href);
        this.fireTocLoaded(content);
    }
    get maxSections() {
        return this._package?.maxSections ?? 0;
    }
    async loadAndRenderSection(sectionId, prepend, initial) {
        if (sectionId <= 0 || sectionId > this.maxSections) {
            warn(`Book.loadAndRenderSection: invalid sectionId value: ${sectionId}`);
            return false;
        }
        if (this.isLoading)
            return false;
        this.setLoading(true);
        let completed = false;
        try {
            info(`Book.loadAndRenderSection: loading section ${sectionId}`);
            const { _package: { spine }, } = this;
            const sectionRef = spine[sectionId];
            if (!sectionRef) {
                throw Error(`Book.loadAndRenderSection: Spine not found for section '${sectionId}'`);
            }
            this.beginLoadSection(sectionId);
            let content = await this._contentLoader?.getSection(sectionRef.href || "");
            if (!content) {
                throw Error(`Book.loadAndRenderSection: Unable to load the contents of section '${sectionId}'(${sectionRef.href})`);
            }
            const { _scrollHandler: scrollHandler } = this;
            this.sectionLoaded(sectionId);
            const { preContent, postContent } = this.beginRenderSection(sectionId);
            if (preContent)
                content = preContent + content;
            if (postContent)
                content += postContent;
            const scrollPos = scrollHandler?.scrollPosition ?? [0, 0];
            const section = document.createElement("article");
            section.id = this.getContentId(sectionId);
            section.innerHTML = content;
            if (prepend === true)
                this._renderTo?.prepend(section);
            else
                this._renderTo?.append(section);
            this.resetScrolling(scrollPos, section, prepend);
            this.observeForLocationChange(section);
            this._scrollHandler?.setup(sectionId, section, this.getSectionLoadType(prepend, initial));
            this.sectionRendered(sectionId, section, initial);
            completed = true;
        }
        finally {
            this.setLoading(false);
            info(`Book.loadAndRenderSection: successfully rendered section ${sectionId}!`);
        }
        return completed;
    }
    getSectionLoadType(prepend, initial) {
        return initial === true
            ? LoadingTypes.BOTH
            : prepend === true
                ? LoadingTypes.TOP
                : LoadingTypes.BOTTOM;
    }
    resetScrolling(prevScrollPos, newSection, prepend) {
        if (!this._scrollHandler) {
            const PageLoadObserverType = this.viewMode === "spread" ? SpreadPageLoadObserver : PageLoadObserver;
            const scrollHandler = new PageLoadObserverType(this.maxSections, this, this._renderTo);
            //scrollHandler.info = info.bind(this);
            scrollHandler.initScrolling(this._renderTo, this.scroller);
            this._scrollHandler = scrollHandler;
        }
        else if (prepend &&
            areEqual(prevScrollPos, this._scrollHandler.scrollPosition)) {
            // move scroll position back to where it was
            const [x] = prevScrollPos;
            this._scrollHandler.scrollTo(x, newSection.clientHeight);
        }
        this._scrollHandler?.resetScrolling();
    }
    setLoading(isLoading) {
        this._isLoading = isLoading;
        this.toggleSpinner(isLoading);
    }
    get isLoading() {
        return this._isLoading;
    }
    /**
     * Sets up IntersectionObserver for asynchronously monitoring
     * elements that intersect with the scroller/container
     */
    observeForLocationChange(section) {
        let observer = this._locationChangeObserver;
        if (!observer) {
            const LocationObserverType = this.viewMode === "spread" ? SpreadLocationObserver : LocationObserver;
            observer = new LocationObserverType(this._renderTo);
            observer.onLocationChanged(this.handleLocationChanged.bind(this));
            this._locationChangeObserver = observer;
        }
        observer.observeForLocationChange(section);
    }
    handleLocationChanged(e) {
        const { detail: { topElement }, } = e;
        const { id } = topElement;
        this._location = id;
        this.emit(Book.Events.locationChanged, {
            id,
            topElement,
        });
    }
    beginLoadSection(sectionId) {
        info("starting to load section ", sectionId);
    }
    sectionLoaded(sectionId) {
        info("section ", sectionId, " loaded!");
    }
    beginRenderSection(sectionId) {
        if (sectionId > 1 && this._options.mode === "spread") {
            const id = this.getContentId(sectionId);
            const leftAnchor = `<div id="${id}prevLoader" class="hidden" data-pseudo-loader = "true" style="height: 100px; width: 100%;"></div>`;
            return { preContent: leftAnchor, postContent: "" };
        }
        return { preContent: "", postContent: "" };
    }
    getContentId(sectionId) {
        return `section${sectionId}`;
    }
    sectionRendered(sectionId, content, isInitialRendering) {
        if (isInitialRendering && this._options?.location?.id) {
            const { location: { id }, } = this._options;
            const elem = document.getElementById(id);
            elem?.scrollIntoView();
            info(`Activated location '${id}'`);
        }
        // load and render highlighted content
        this.renderHighlights(sectionId, content);
        this._scrollHandler?.adjustScrollbarPosition(sectionId, isInitialRendering);
        this.registerAutoSelect(content);
        this.emit(Book.Events.sectionRendered, { sectionId });
    }
    renderHighlights(sectionId, content) {
        if (!this._options.enableHighlights) {
            info("Book.renderHighlights - highlighting not enabled.");
            return;
        }
        if (!this.highlighter) {
            warn("Book.renderHighlights - highlighter is not specified!.");
            return;
        }
        //const highlighter = this.highlighterFactory();
        this.highlighter.highlightAll(sectionId, content);
        //enqueueTask(highlighter.getBookHighlights, { bookId: sectionId });
        //enqueueTask(highlighter.highlightVerses, { container: content });
    }
    fireTocLoaded(content) {
        this.emit(Book.Events.tocLoaded, { toc: content });
    }
    handleSelection() {
        if (document.getSelection()?.type !== "Range") {
            if (this._selectionPopup)
                this.hideSelectionPopup();
            return;
        }
        if (this._selectionPopup)
            return;
        // show the popup after a small delay!
        if (this._popupDebounceTimer) {
            clearTimeout(this._popupDebounceTimer);
            this._popupDebounceTimer = undefined;
        }
        this._popupDebounceTimer = setTimeout(this.showSelectionPopup.bind(this), 300);
    }
    showSelectionPopup() {
        info("showSelectionPopup");
        if (this._selectionPopup)
            return;
        const selection = document.getSelection();
        if (!selection)
            return;
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        const popup = this.popupProvider.getOrCreateSelectionPopup(this);
        const fontSize = getFontSize(popup);
        const height = popup.clientHeight
            ? popup.clientHeight
            : (popup.offsetHeight ? popup.offsetHeight : 50) + fontSize * 1.2;
        let leftDelta = 0;
        const win = window;
        if (win.previousPopupWidth &&
            win.previousPopupWidth + rect.left > window.innerWidth) {
            leftDelta = win.previousPopupWidth + rect.left - window.innerWidth;
        }
        popup.style.left = `${rect.left - leftDelta}px`;
        popup.style.top = `${rect.top - height}px`;
        popup.classList.remove("hidden");
        this._selectionPopup = popup;
        clearTimeout(this._popupDebounceTimer);
        this._popupDebounceTimer = undefined;
    }
    hideSelectionPopup() {
        const { _selectionPopup: popup } = this;
        if (!popup)
            return;
        window.previousPopupWidth = popup.clientWidth;
        popup.classList.add("hidden");
        popup.style.left = "-1000px";
        this._selectionPopup = undefined;
    }
    registerAutoSelect(container) {
        if (!this._options?.autoSelectOnClickSelector) {
            info("Book.registerAutoSelect - options.autoSelectOnClick not set!");
            return;
        }
        container
            //.querySelectorAll(this._options?.autoSelectOnClick)
            //.forEach((elem) =>
            //elem
            .addEventListener("click", this.handleSectionClick.bind(this));
        //);
    }
    onPopupItemSelected(command) {
        info("Book.onPopupItemSelected - popup item selected: ", command);
        this.hideSelectionPopup();
        if (this._popupHandler) {
            const isHandled = this._popupHandler.onPopupItemSelected(command);
            if (isHandled)
                return;
        }
        switch (command) {
            case "copy":
                const selection = this._selectionFormatter();
                const text = selection?.title
                    ? `${selection.title.displayText}\n${selection.content}`
                    : selection.content;
                navigator.clipboard.writeText(text);
                info("Selected text copied to the Clipboard!");
                return;
            case "share":
                const selection2 = this._selectionFormatter();
                navigator.share({
                    text: selection2.content,
                    title: selection2.title?.displayText,
                });
                info("Selected text shared!");
                return;
            default:
                warn(`${command} - not implemented!`);
        }
    }
    handleSectionClick(e) {
        const target = e.target;
        const { _options: { autoSelectOnClickSelector: autoSelectOnClick, autoSelectSelector, }, } = this;
        if (!target || !autoSelectOnClick)
            return;
        let elem = target;
        while (elem && !elem.matches(autoSelectOnClick))
            elem = elem.parentElement;
        if (autoSelectSelector) {
            while (elem && !elem.matches(autoSelectSelector))
                elem = elem.parentElement;
        }
        if (!elem) {
            // warn("Couldn't find element to select.");
            return;
        }
        // select!!
        var range = new Range();
        range.setStart(elem, 0);
        range.setEnd(elem, elem.childNodes.length);
        const selection = document.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
    }
    get highlighter() {
        return this._highlighter ?? NoOpHighlighter.instance;
    }
    set highlighter(value) {
        this._highlighter = value;
    }
    get popupProvider() {
        return this._popupProvider ?? DefaultPopupProvider.instance;
    }
    set popupProvider(provider) {
        this._popupProvider = provider;
    }
    get popupHandler() {
        return this._popupHandler;
    }
    set popupHandler(handler) {
        this._popupHandler = handler;
    }
    get selectionFormatter() {
        return this._selectionFormatter;
    }
    set selectionFormatter(formatter) {
        this._selectionFormatter = formatter;
    }
    get scroller() {
        return (document.querySelector(".scroller") ??
            this._renderTo?.parentElement ??
            undefined);
    }
    toggleSpinner(show) {
        if (!this._spinner) {
            import("./Spinner.js").then(() => {
                const e = document.createElement("js-spinner");
                e.id = "spinner";
                e.setAttribute("position", "top-right");
                e.setAttribute("zoom", "25%");
                document.body.append(e);
                this._spinner = e;
            });
            return;
        }
        if (!this._spinner)
            return;
        if (show !== true)
            this._spinner.style.display = "none";
        else
            this._spinner.style.display = "";
    }
} // class Book
function areEqual(u, v) {
    const [ux, uy] = u;
    const [vx, vy] = v;
    return ux === vx && uy === vy;
}
class NoOpHighlighter {
    highlightAll(sectionId, container) {
        warn("DefaultHighlighter.highlightAll sectionId=", sectionId, ", container=", container);
    }
    highlight(annotation, container) {
        warn("DefaultHighlighter.highlight ", annotation);
    }
    static instance = new NoOpHighlighter();
}
export default Book;
