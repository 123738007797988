import BaseObject from "./BaseObject.js";
import { LoadingTypes } from "./Interfaces/ScrollHandler.js";
import { getElement } from "./utils.js";
/**
 * Uses IntersectionObserver for determining when to load the next or previous section (chapter)
 * in a continuous scrolled viewer
 */
export default class PageLoadObserver extends BaseObject {
    _scroller;
    _observer;
    _sectionLoader;
    _maxSection;
    _isPaused = false;
    _anchors = new Map();
    constructor(maxSections, sectionLoader) {
        super();
        this._sectionLoader = sectionLoader;
        this._maxSection = Number(maxSections) || 0;
    }
    get isPaused() {
        return this._isPaused;
    }
    pause() {
        this._isPaused = true;
    }
    resume() {
        this._isPaused = false;
    }
    get scroller() {
        return this._scroller;
    }
    get scrollPosition() {
        if (!this._scroller)
            return [0, 0];
        const { scrollLeft, scrollTop } = this._scroller;
        return [scrollLeft, scrollTop];
    }
    scrollTo(x, y) {
        const { scroller } = this;
        if (!scroller)
            return;
        this._isPaused = true;
        scroller.scrollLeft = x;
        scroller.scrollTop = y;
        info(`PageLoadObserver.scrollTo: ScrollPosition set to: (${x}, ${y})`);
        this._isPaused = false;
    }
    /**
     * setup scroll detection
     * @param container
     * @param scroller
     */
    initScrolling(container, scroller) {
        info("PageLoadObserver: initScrolling");
        this._scroller = getElement(scroller, container.parentElement);
        this.getOrCreateObserver(container);
        info("PageLoadObserver - successfully initialized! ");
    }
    resetScrolling() {
        info("PageLoadObserver: resetScrolling");
        const { _anchors, _observer } = this;
        _anchors.clear();
        if (_observer)
            _observer.disconnect();
    }
    adjustScrollbarPosition(sectionId, isInitialRendering, scrollTop = 100) {
        info("PageLoadObserver: adjustScrollbarPosition");
        if (isInitialRendering &&
            this._scroller?.scrollTop === 0 &&
            sectionId > 1 /* can scroll top */) {
            this.scrollTo(0, scrollTop);
        }
    }
    setup(sectionId, content, loadingType) {
        this.pause();
        let observer = this.getOrCreateObserver(content);
        info(`PageLoadObserver: setting up PageLoadObserver for ${content.id}, type=${loadingType}`);
        if ((loadingType & LoadingTypes.TOP) === LoadingTypes.TOP &&
            sectionId > 1) {
            const anchor = this.getTopScrollingAnchorElement(content, sectionId);
            if (!anchor) {
                warn("PageLoadObserver: unable to find top/left most anchor element for pageload scroll observer!");
            }
            else {
                info(`PageLoadObserver: observing for top scrolling (prev loading). id=${anchor.id}`);
                observer?.observe(anchor);
            }
        }
        if ((loadingType & LoadingTypes.BOTTOM) === LoadingTypes.BOTTOM &&
            sectionId < this._maxSection) {
            const anchor = this.getBottomScrollingAnchorElement(content, sectionId);
            if (!anchor) {
                warn("PageLoadObserver: unable to find bottom/right most anchor element for page load scroll observer!");
            }
            else {
                info(`PageLoadObserver: observing for bottom scrolling (next loading). id=${anchor.id}`);
                observer?.observe(anchor);
            }
        }
        this.resume();
    }
    disconnect() {
        this._observer?.disconnect();
        this._observer = undefined;
    }
    getOrCreateObserver(container) {
        if (this._observer)
            return this._observer;
        const { _scroller: root } = this;
        if (!root) {
            warn("PageLoadObserver - scroller is not set!");
            return;
        }
        let width = root.clientWidth;
        width = (width - container.clientWidth) / 2;
        const options = {
            root: root,
            rootMargin: width > 0 ? `0px ${width}px` : "0px",
            threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        };
        info(`PageLoadObserver: observer options: `, options);
        this._observer = new IntersectionObserver(this.handleIntersect.bind(this), options);
        return this._observer;
    }
    getTopScrollingAnchorElement(container, sectionId) {
        return this.createAndInsertPseudoElementForLoading(container, sectionId, LoadingTypes.TOP);
    }
    getBottomScrollingAnchorElement(container, sectionId) {
        return this.createAndInsertPseudoElementForLoading(container, sectionId, LoadingTypes.BOTTOM);
    }
    createAndInsertPseudoElementForLoading(container, sectionId, anchorType) {
        const elem = document.createElement("div");
        const type = anchorType === LoadingTypes.BOTTOM ? "next" : "prev";
        elem.id = `${container.id}${type}Loader`;
        elem.dataset.pseudoLoader = "true";
        elem.setAttribute("style", "height: 100px; width: 100%;");
        if (anchorType === LoadingTypes.BOTTOM)
            container.append(elem);
        else
            container.prepend(elem);
        this.createAnchorData(sectionId, elem.id, anchorType);
        return elem;
    }
    createAnchorData(sectionId, containerId, anchorType) {
        const { _anchors: anchors } = this;
        if (anchors.has(containerId))
            return;
        const anchor = {
            intersectionRatio: 0,
            anchorType,
            nextId: anchorType === LoadingTypes.TOP ? sectionId - 1 : sectionId + 1,
        };
        //this.setAnchorData(anchor);
        anchors.set(containerId, this.setAnchorData(anchor));
    }
    setAnchorData(anchor) {
        return anchor;
    }
    async handleIntersect(entries) {
        info(`PageLoadObserver.handleIntersect: isPaused=${this._isPaused}`);
        if (this._isPaused) {
            warn("PageLoadObserver.handleIntersect: paused!");
            return;
        }
        // Array.from(entries).forEach(e => console.log(`${e.target.id}: ${e.time}`));
        const elems = this.getScrollRefElements(entries);
        const { top: topAnchor, bottom: bottomAnchor } = elems;
        info(`PageLoadObserver::getScrollRefElements: top: ${topAnchor?.id}; bottom: ${bottomAnchor?.id}`);
        const { _anchors: anchors, _sectionLoader: sectionLoader } = this;
        const topAnchorId = topAnchor?.id;
        const bottomAnchorId = bottomAnchor?.id;
        if (topAnchorId &&
            anchors.has(topAnchorId) &&
            (await sectionLoader.loadAndRenderSection(anchors.get(topAnchorId).nextId, true))) {
            this.unobserve(topAnchor);
        }
        if (bottomAnchorId &&
            anchors.has(bottomAnchorId) &&
            (await sectionLoader.loadAndRenderSection(anchors.get(bottomAnchorId).nextId, false))) {
            this.unobserve(bottomAnchor);
        }
    }
    getScrollRefElements(entries) {
        info("PageLoadObserver.getScrollRefElements");
        let topElem = null;
        let bottomElem = null;
        const minIntersection = 0.1;
        const anchors = this._anchors;
        for (const entry of entries) {
            const { target: { id: targetId }, isIntersecting, } = entry;
            if (!isIntersecting || !anchors.has(targetId))
                continue;
            const { target, intersectionRatio } = entry;
            let anchor = anchors.get(targetId);
            if (!anchor) {
                warn("PageLoadObserver.getScrollRefElements - saved scroll info not found for ", targetId);
                continue;
            }
            const { intersectionRatio: prevIntersectionRatio, anchorType } = anchor;
            info(`PageLoadObserver.getScrollRefElements - Previous IntersectionRatio: ${prevIntersectionRatio}; anchorType: ${anchorType}`);
            if (anchorType == LoadingTypes.TOP &&
                intersectionRatio > prevIntersectionRatio &&
                intersectionRatio >= minIntersection) {
                // scrolling up
                // info("** WOHOO! scrolling up!!", target);
                topElem = target;
            }
            else if (anchorType == LoadingTypes.BOTTOM &&
                intersectionRatio > prevIntersectionRatio &&
                intersectionRatio >= minIntersection) {
                // scrolling down and target is the top element
                // info("** WOHOO! scrolling down!!", target);
                bottomElem = target;
            }
            anchors.set(targetId, { ...anchor, intersectionRatio });
        }
        return { top: topElem, bottom: bottomElem };
    }
    unobserve(element) {
        const htmlElem = element;
        if (!htmlElem.dataset.pseudoLoader) {
            warn("PageLoadObserver.unobserve - element is not a anchor/pseudoLoader!");
            return;
        }
        const { _observer, _anchors } = this;
        _observer?.unobserve(element);
        element.remove();
        _anchors.delete(element.id);
    }
    /**
     * sets SectionLoader callback
     * @param sectionLoader
     * @returns
     */
    withSectionLoader(sectionLoader) {
        this._sectionLoader = sectionLoader;
        return this;
    }
}
