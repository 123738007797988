export default class BaseObject extends EventTarget {
    _events = {};
    on(eventName, handler) {
        const event = new Event(eventName);
        this.addEventListener(eventName, handler);
        this._events[eventName] = event;
    }
    emit(eventName, data) {
        if (!data) {
            const event = this._events[eventName];
            if (!event)
                throw Error(`Unknown event: ${eventName}`);
            this.dispatchEvent(event);
            return;
        }
        this.dispatchEvent(new CustomEvent(eventName, { detail: data }));
    }
}
