export default class Spinner extends HTMLElement {
    static ATTR_COLOR = "color";
    static ATTR_POSITION = "position";
    static ATTR_ZOOM = "zoom";
    constructor() {
        super();
    }
    async connectedCallback() {
        const shadow = this.attachShadow({ mode: "closed" });
        const attrColor = this.getAttribute(Spinner.ATTR_COLOR);
        const color = `:root {--spinner-color: var(--brand-color, ${attrColor ? attrColor : "#fe718d"}); }`;
        const style = document.createElement("style");
        style.textContent =
            color +
                `

      :host {
        all: initial; /* Reverts all properties to HTML spec defaults */
        }

        @keyframes spin {
            0% { transform: rotate(0) }
            100% { transform: rotate(360deg) }
        }
        .spinner div { box-sizing: border-box!important }
        .spinner > div {
            position: absolute;
            width: 144px;
            height: 144px;
            top: 28px;
            left: 28px;
            border-radius: 50%;
            border: 16px solid #000;
            border-color: var(--spinner-color) transparent var(--spinner-color) transparent;
            animation: spin 1s linear infinite;
        }
        .spinner > div:nth-child(2) { border-color: transparent }
        .spinner > div:nth-child(2) div {
            position: absolute;
            width: 100%;
            height: 100%;
            transform: rotate(45deg);
        }
        .spinner > div:nth-child(2) div:before, .spinner > div:nth-child(2) div:after { 
            content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            top: -16px;
            left: 48px;
            background: var(--spinner-color);
            border-radius: 50%;
            box-shadow: 0 128px 0 0 var(--spinner-color);
        }
        .spinner > div:nth-child(2) div:after { 
            left: -16px;
            top: 48px;
            box-shadow: 128px 0 0 0 var(--spinner-color);
        }
        .loading-spinner {
            width: 200px;
            height: 200px;
            display: inline-block;
            overflow: hidden;
            background: #ffffff;
        }

        .top-right {
            position: fixed;
            top: 0;
            right: 0;
            margin: 8px;
        }

        .zoomed {
            zoom: 25%;
        }

        .transparent {
            background-color: transparent;
        }

        .spinner {
            width: 100%;
            height: 100%;
            position: relative;
            transform: translateZ(0) scale(1);
            backface-visibility: hidden;
            transform-origin: 0 0; /* see note above */
        }
        .spinner div { box-sizing: content-box; }
            `;
        shadow.appendChild(style);
        const content = document.createElement("div");
        content.setAttribute("part", "root");
        content.className = "loading-spinner zoomed transparent"; // top-right
        let attr = this.getAttribute(Spinner.ATTR_POSITION);
        if (attr)
            content.classList.add(attr);
        attr = this.getAttribute(Spinner.ATTR_ZOOM);
        if (attr)
            style.append(`.zoomed {zoom: ${attr};}`); // content.classList.add(attr);
        content.innerHTML = `
    <div class="spinner" part="spinner">
        <div></div>
        <div>
            <div></div>
        </div>
    </div>
    `;
        shadow.appendChild(content);
    }
}
window.customElements.define("js-spinner", Spinner);
