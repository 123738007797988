import { default as PageLoadObserverBase, } from "../PageLoadObserver.js";
import { LoadingTypes } from "../Interfaces/ScrollHandler.js";
import { getOrCreateId } from "../utils.js";
/**
 * Uses IntersectionObserver for determining when to load the next or previous section (chapter)
 * in a continuous scrolled viewer
 */
export default class PageLoadObserver extends PageLoadObserverBase {
    _container;
    constructor(maxSections, sectionLoader, container) {
        super(maxSections, sectionLoader);
        this._container = container;
    }
    get scroller() {
        return this._container ?? this._scroller;
    }
    adjustScrollbarPosition(sectionId, isInitialRendering, scrollTop = 0) {
        const scroller = this.scroller;
        if (isInitialRendering &&
            scroller?.scrollLeft === 0 &&
            sectionId > 1 /* can scroll top */) {
            const left = scroller.clientWidth / 2;
            this.scrollTo(left, scrollTop);
        }
    }
    setAnchorData(anchor) {
        return { ...anchor, right: 0 };
    }
    getScrollRefElements(entries) {
        const prefix = "PageLoadObserver[spread].getScrollRefElements - ";
        info(prefix);
        const allEntries = Array.from(entries.filter((e) => e.isIntersecting === true));
        info(prefix, `${allEntries.length} intersecting entries.`);
        if (!allEntries.length) {
            return { top: null, bottom: null };
        }
        const firstEntry = allEntries[0];
        const lastEntry = allEntries[allEntries.length - 1];
        let leftmostElem = null;
        let rightmostElem = null;
        const { _anchors: anchors } = this;
        if (isLeftScrolling(lastEntry)) {
            info(prefix, "scrolling to the left!");
            rightmostElem = lastEntry.target;
        }
        else if (isRightScrolling(firstEntry)) {
            info(prefix, "scrolling to the right!");
            leftmostElem = firstEntry.target;
        }
        else {
            info(prefix, "no left or right intersecting elements found!");
        }
        allEntries.forEach((e) => {
            const { target, intersectionRatio, boundingClientRect: { right }, } = e;
            const id = getOrCreateId(target);
            const anchor = anchors.get(id);
            if (anchor) {
                anchors.set(id, { ...anchor, intersectionRatio, right });
            }
        });
        return { top: leftmostElem, bottom: rightmostElem };
        function isLeftScrolling(entry) {
            if (!anchors.has(entry.target.id))
                return;
            const { isIntersecting, target, intersectionRatio, boundingClientRect: { right }, } = entry;
            const { intersectionRatio: prevIntersectionRatio, right: prevRight } = anchors.get(target.id);
            return (isIntersecting &&
                intersectionRatio > prevIntersectionRatio &&
                right < (prevRight ?? 0) &&
                intersectionRatio >= 0.1);
        }
        function isRightScrolling(entry) {
            if (!anchors.has(entry.target.id))
                return;
            const { isIntersecting, target, intersectionRatio, boundingClientRect: { right }, } = entry;
            const { intersectionRatio: prevIntersectionRatio, right: prevRight } = anchors.get(target.id);
            return (isIntersecting &&
                intersectionRatio > prevIntersectionRatio &&
                right > (prevRight ?? 0) &&
                intersectionRatio >= 0.1);
        }
    }
    getTopScrollingAnchorElement(container, sectionId) {
        const elem = document.getElementById(`${container.id}prevLoader`);
        if (elem)
            this.createAnchorData(sectionId, elem.id, LoadingTypes.TOP);
        info(`PageLoadObserver[spread].getTopScrollingAnchorElement: `, elem?.id
        //"color: dodgerblue"
        );
        return elem;
    }
}
