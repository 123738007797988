import parsePackageContent from "./PackageHelper.js";
export default class ContentLoader {
    _packageUrl;
    _contentUrl;
    constructor(packageUrl, contentUrl) {
        this._packageUrl = packageUrl;
        this._contentUrl = contentUrl;
    }
    /**
     * loads and returns the contents of a specified section or chapter from a epub folder
     **/
    async getSection(href) {
        return await fetch(`${this._contentUrl}/${href}` /*, createRequest("GET")*/).then((response) => (response.ok ? response.text() : null));
    }
    /**
     * loads and returns Spine from a epub package.opf file
     **/
    async getSpine() {
        return await fetch(this._packageUrl /*, createRequest("GET")*/)
            .then(async (response) => response.text())
            .then(parsePackageContent);
    }
    /**
     * loads and returns the contents of table of contents
     **/
    async getToc(href) {
        return await fetch(`${this._contentUrl}/${href}` /*, createRequest("GET")*/).then((response) => (response.ok ? response.text() : null));
    }
} // contentLoader
/*
const createRequest = (method, contentType) => ({
  method: method || "GET", // *GET, POST, PUT, DELETE, etc.
  mode: "no-cors", // no-cors, *cors, same-origin
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //credentials: "same-origin", // include, *same-origin, omit
  headers:
    contentType && method !== "GET"
      ? {
          "Content-Type": contentType,
        }
      : {},
});
*/
