export default class DefaultPopupProvider {
    static instance = new DefaultPopupProvider();
    getOrCreateSelectionPopup(popupHandler, dividers = false) {
        let elem = document.getElementById("selection-popup");
        if (elem)
            return elem;
        elem = document.createElement("menu");
        elem.setAttribute("id", "selection-popup");
        elem.classList.add("hidden");
        elem.style.position = "absolute";
        elem.style.zIndex = "100";
        if (!popupHandler) {
            elem.innerHTML = `
  <ul>
    <li><button onclick='copySelection()' class='btn'>Copy</button></li>
    <li><button onclick='highlightSelection()' class='btn'>Highlight</button></li>
    <li><button onclick='shareSelection()' class='btn btn-share'>Share</button></li>
    <li><button onclick='addToListSelection()' class='btn'>Add To List</button></li>    
  </ul>
  `;
        }
        else {
            const itemsContainer = document.createElement("div");
            itemsContainer.classList.add("items-container");
            const items = [
                { label: "Copy", key: "copy" },
                { label: "Highlight", key: "highlight" },
                { label: "Highlight...", key: "highlight-more" },
                { label: "Note", key: "note" },
                { label: "Share", key: "share" },
                { label: "Add To List", key: "addToList" },
            ];
            items.forEach((itm) => {
                const li = document.createElement("div");
                li.classList.add("popup-item", itm.key);
                const btn = document.createElement("button");
                btn.classList.add("btn", `btn-${itm.key}`);
                if (itm.key === "highlight") {
                    const s = document.createElement("span");
                    s.innerText = itm.label;
                    s.dataset.key = itm.key;
                    btn.append(s);
                }
                else
                    btn.innerText = itm.label;
                btn.dataset.key = itm.key;
                btn.addEventListener("click", (e) => {
                    const key = e.target.dataset.key;
                    popupHandler.onPopupItemSelected(key);
                });
                li.append(btn);
                itemsContainer.append(li);
                if (itm.key === "highlight") {
                    const color = document.createElement("div");
                    color.classList.add("default-highlight-color");
                    color.dataset.key = itm.key;
                    btn.append(color);
                }
                if (dividers) {
                    const divider = document.createElement("div");
                    divider.classList.add("item-divider");
                    itemsContainer.append(divider);
                }
            });
            elem.append(itemsContainer);
        }
        document.body.appendChild(elem);
        return elem;
    }
}
